import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=a62de918&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=a62de918&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/usr/src/app/components/Logo.vue').default,IconsFacebook: require('/usr/src/app/components/icons/Facebook.vue').default,IconsInstagram: require('/usr/src/app/components/icons/Instagram.vue').default,IconsLinkedIn: require('/usr/src/app/components/icons/LinkedIn.vue').default,IconsTwitter: require('/usr/src/app/components/icons/Twitter.vue').default,IconsYouTube: require('/usr/src/app/components/icons/YouTube.vue').default,IconsTikTok: require('/usr/src/app/components/icons/TikTok.vue').default,KeewayGroupLogo: require('/usr/src/app/components/KeewayGroupLogo.vue').default,LocaleSelector: require('/usr/src/app/components/LocaleSelector.vue').default,WhatsappChat: require('/usr/src/app/components/WhatsappChat.vue').default,Footer: require('/usr/src/app/components/Footer.vue').default})
